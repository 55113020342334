import { useCallback, useContext } from "react";
import {
  HubConnectionState,
} from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTenantIdGroup,
  forceChangeTenantIdGroup,
  setLockCurrentGroup,
} from "../../store/actions/signalrActions";
import { RootState } from "../../store/reducers";
import { useTenant } from "../tenant";
import { SignalRContext } from "../../provider/SignalRToReduxProvider";

export const useBroadcaster = (): signalR.HubConnection | null => {
  const connection = useContext(SignalRContext);

  return connection;
};


export default useBroadcaster;

export const useTenantGroup = () => {
  const currentGroup = useSelector(
    (state: RootState) => state.signalrReducer.currentTenantGroup
  );

  const isLocked = useSelector(
    (state: RootState) => state.signalrReducer.groupIsLocked
  );

  const connection = useBroadcaster();
  const dispatch = useDispatch();
  const tenant = useTenant();

  const changeGroup = useCallback(
    async (newGroup: string) => {
      if (!connection) return;
      if (connection.state !== HubConnectionState.Connected) return;

      dispatch(changeTenantIdGroup(connection, newGroup));
    },
    [connection, dispatch]
  );

  const forceCurrentGroup = useCallback(
    async (newGroup: string) => {
      if (!connection) return;
      if (connection.state !== HubConnectionState.Connected) return;


      if(!isLocked)
        dispatch(setLockCurrentGroup(true));

      if(currentGroup !== newGroup)
        dispatch(forceChangeTenantIdGroup(connection, newGroup));
    },
    [connection, currentGroup, isLocked, dispatch]
  );


  const undoForcedGroup = useCallback(() => {
    // console.log("undoing forced group");
    
    if(isLocked)
      dispatch(setLockCurrentGroup(false))
    
    if (!tenant || !connection) return;
    if (connection.state !== HubConnectionState.Connected) return;

    dispatch(changeTenantIdGroup(connection, tenant.identifier));
  }, [dispatch, isLocked, tenant, connection]);



  return { currentGroup, changeGroup, forceCurrentGroup, undoForcedGroup };
};
