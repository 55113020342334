import { useCallback } from "react";
import { getTenants } from "../../utilities/axios/admin-api-calls/adminCalls";
import { PagedResponse, PaginationQuery } from "../../utilities/api";
import ItemDisplay from "../display/ItemDisplay";
import { BaseModel, Tenant } from "../../models";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import { Filter } from "../device-filter/DeviceFilter";

type props = {
  onTenantChosen: (tenant: Tenant) => void;
  disabledTenantIds?: string[];
  width?: number | string;
  loadingComponent?: "circularProgress" | "skeleton" | "none";
};

const TenantDisplay = ({
  onTenantChosen,
  disabledTenantIds = [],
  width = "inherit",
  loadingComponent = "circularProgress",
}: props) => {
  const getTenantsPaginationQuery = useCallback(
    async (paginationQuery: PaginationQuery, filter: Filter) => {
      let result = await getTenants(paginationQuery, filter.searchTerm);
      if (result === null) return null;

      let tenants: PagedResponse<BaseModel> = {
        ...result,
        items: result.items.map((tenant: Tenant) => {
          return { ...tenant, id: tenant.identifier };
        }),
      };

      return tenants;
    },
    []
  );

  const handleTenantChosen = (item: Item) => {
    let tenant = item as unknown as Tenant;
    onTenantChosen(tenant);
  };

  return (
    <ItemDisplay
    itemName="tenants"
    width={width}
    getItems={getTenantsPaginationQuery}
    labelProperty={"name"}
    handleItemChosen={handleTenantChosen}
    isDisabled={(item) => disabledTenantIds.some(ten => ten === item.id) }
    loadingComponent={loadingComponent}
    maxItemsPerPage={9}
    />
  );
};

export default TenantDisplay;
