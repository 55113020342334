import { useCallback, useState } from "react";
import { getRooms } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";

import { PaginationQuery } from "../../utilities/api";
import ItemDisplay from "../display/ItemDisplay";
import { RoomModel } from "../../models/locations.model";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import { Filter } from "../device-filter/DeviceFilter";
import { TableCell, TableRow } from "@mui/material";
import { ItemComponentProps, itemSelectionFocus } from "../item-lists/ItemList";

type props = {
  handleRoomChosen: (room: RoomModel) => void;
  disabledRoomId?: string | null;
};

const RoomsDisplay = ({ handleRoomChosen, disabledRoomId }: props) => {
  const [selected, setSelected] = useState<string>("");
  const getRoomsDisplaysPaginationQuery = useCallback(
    (paginationQuery: PaginationQuery, filter: Filter) =>
      getRooms(paginationQuery, filter.searchTerm),
    []
  );

  const handleRoomAsItemChoosen = (room: Item) => {
    setSelected(room.id);
    handleRoomChosen(room as RoomModel);
  };

  const RoomDisplayItem = (props: ItemComponentProps) => {
    let room = props.model as RoomModel;
    return (
      <TableRow
        key={room.id}
        sx={{
          maxWidth: "100%",
          textAlign: "center",
          "&:focus": itemSelectionFocus,
          "&:hover": itemSelectionFocus,
          ...(selected === room.id && itemSelectionFocus),
        }}
        onClick={() => props.onModelChosen(room)}
      >
        <TableCell
          sx={{
            align: "center",
            fontSize: "small",
            color: "inherit",
            fontWeight: "inherit",
          }}
        >
          {room.name}
        </TableCell>
        <TableCell
          sx={{
            align: "center",
            fontSize: "small",
            color: "gray",
          }}
        >
          {room.type.name}
        </TableCell>
        <TableCell
          sx={{
            align: "right",
            fontSize: "small",
            color: "gray",
          }}
        >
          {room.floor.name}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <ItemDisplay
      itemName="rooms"
      useTable={true}
      getItems={getRoomsDisplaysPaginationQuery}
      labelProperty={"name"}
      disabledItemId={disabledRoomId}
      handleItemChosen={handleRoomAsItemChoosen}
      itemComponent={RoomDisplayItem}
      maxItemsPerPage={6}
    />
  );
};

export default RoomsDisplay;
