import axios, { AxiosError, AxiosResponse } from "axios";
import {
  CreateFluidDto,
  Device,
  DeviceStatus,
  FluidTypeDto,
  UpdateTenantDevice,
} from "../../../../models";
import {
  DetailedDevice,
  RemainderModel,
} from "../../../../models/detailedDevice.model";
import { DeviceEventCollection } from "../../../../models/deviceEvents.model";
import {
  CreateDeviceDto,
  CreateRoomUsage,
} from "../../../../models/locations.model";
import {
  API_URL,
  IPaginationQuery,
  PagedResponse,
  PaginationQuery,
  repeatedArrayFormat,
} from "../../../api";

export const getDeviceById = async (
  tenantId: string,
  deviceId: string
): Promise<Device | null> => {
  try {
    var response = await axios.get(
      `${API_URL}/tenants/${tenantId}/devices/${deviceId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDetailedDeviceById = async (
  tenantId: string,
  deviceId: string
): Promise<AxiosResponse<DetailedDevice, any> | null> => {
  try {
    var response = await axios.get(
      `${API_URL}/tenants/${tenantId}/devices/${deviceId}/details`,
      {
        headers: {
          "x-tenant-identifier": tenantId
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response ?? null;
  }
};

export const createDevice = async (
  tenantId: string,
  room: CreateDeviceDto
): Promise<number> => {
  try {
    var response = await axios.post(
      `${API_URL}/tenants/${tenantId}/devices`,
      room
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const updateDevice = async (
  tenantId: string,
  deviceId: string,
  updateDevice: UpdateTenantDevice
): Promise<number> => {
  try {
    var response = await axios.put(
      `${API_URL}/tenants/${tenantId}/devices/${deviceId}`,
      updateDevice
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const getDevices = async (
  tenantId: string,
  paginationQuery: IPaginationQuery,
  searchTerm: string = ""
): Promise<PagedResponse<Device> | null> => {
  try {
    let params = { term: searchTerm };
    let response = await axios.get(
      paginationQuery.toUrl(`/tenants/${tenantId}/devices`),
      {
        params,
      }
    );
    return response.data as PagedResponse<Device>;
  } catch (error) {
    return null;
  }
};

export const getDeviceEventsByDevice = async (
  tenantId: string,
  deviceId: string,
  paginationQuery: IPaginationQuery,
  typesIncluded?: number[],
  bluetoothFilter?: boolean,
  transactionFilter?: boolean
): Promise<PagedResponse<DeviceEventCollection> | null> => {
  try {
    let params = {
      typesIncluded: typesIncluded ?? [],
      bluetoothEventsIncluded: bluetoothFilter ?? null,
      transactionEventsIncluded: transactionFilter ?? null,
    };

    let response = await axios.get(
      paginationQuery.toUrl(`/tenants/${tenantId}/devices/${deviceId}/events`),
      {
        params,
        paramsSerializer: repeatedArrayFormat,
      }
    );
    return response.data as PagedResponse<DeviceEventCollection>;
  } catch (error) {
    return null;
  }
};

export const getLatestDeviceEvents = async (
  paginationQuery: IPaginationQuery,
  typesIncluded?: number[],
  bluetoothFilter?: boolean,
  transactionFilter?: boolean,
  summaryFilter?: boolean
): Promise<PagedResponse<DeviceEventCollection> | null> => {
  try {
    let params = {
      typesIncluded: typesIncluded ?? [],
      bluetoothEventsIncluded: bluetoothFilter ?? null,
      transactionEventsIncluded: transactionFilter ?? null,
      summaryEventsIcluded: summaryFilter ?? null,
    };

    let res = await axios.get(paginationQuery.toUrl(`/device-events`), {
      params,
      paramsSerializer: repeatedArrayFormat,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getStatus = async (
  deviceId: string,
  tenantId: string
): Promise<PagedResponse<DeviceStatus> | null> => {
  try {
    var response = await axios.get(
      new PaginationQuery(1, 1).toUrl(
        `/tenants/${tenantId}/devices/${deviceId}/status`
      )
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createFluid = async (
  deviceId: string,
  tenantId: string,
  createDeviceStatusDto: CreateFluidDto
): Promise<RemainderModel | null> => {
  try {
    var response = await axios.post(
      `${API_URL}/tenants/${tenantId}/devices/${deviceId}/fluid`,
      createDeviceStatusDto
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateFluidKind = async (
  deviceId: string,
  tenantId: string,
  dto: FluidTypeDto
): Promise<RemainderModel | null> => {
  try {
    var response = await axios.put(
      `${API_URL}/tenants/${tenantId}/devices/${deviceId}/fluid/type`,
      dto
    );
    return response.data as RemainderModel;
  } catch (error) {
    return null;
  }
};

export const changeBatteries = async (
  deviceId: string,
  tenantId: string
): Promise<number> => {
  try {
    var response = await axios.post(
      `${API_URL}/tenants/${tenantId}/devices/${deviceId}/batteries`
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const postUsageProfileToRoom = async (
  roomId: string,
  usage: CreateRoomUsage
): Promise<number> => {
  try {
    var response = await axios.post(
      `${API_URL}/rooms/${roomId}/usage-profile`,
      usage
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};
