import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getPremisesForSection } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";

import { PaginationQuery } from "../../utilities/api";
import ItemDisplay from "../display/ItemDisplay";
import { PremiseModel } from "../../models/locations.model";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import { Filter } from "../device-filter/DeviceFilter";
import {useTenantNavigate} from '../../utilities/helpers';

type props = {
  sectionId: string;
};

const SectionPremiseDisplay = ({ sectionId }: props) => {
  const navigate = useTenantNavigate();

  const getSectionPremisesDisplaysPaginationQuery = useCallback(
    (paginationQuery: PaginationQuery, filter: Filter) =>
      getPremisesForSection(paginationQuery, sectionId, filter.searchTerm),
    [sectionId]
  );

  const handlePremiseChosen = (item: Item) => {
    let premise = item as PremiseModel;

    navigate(`premises/${premise.id}`);
  };
  return (
    <ItemDisplay
      itemName="premises"
      getItems={getSectionPremisesDisplaysPaginationQuery}
      labelProperty={"name"}
      handleItemChosen={handlePremiseChosen}
    />
  );
};

export default SectionPremiseDisplay;
