
import { useCallback}  from "react";
import { useNavigate } from "react-router-dom";
import { getRoomsForFloor } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";

import {
  PaginationQuery,

} from "../../utilities/api";
import ItemDisplay from "../display/ItemDisplay";
import { BaseModel } from "../../models";
import { Filter } from "../device-filter/DeviceFilter";
import {useTenantNavigate} from '../../utilities/helpers';


type props = {
  floorId: string
}

const FloorRoomsDisplay = ({floorId}: props) => {
  const navigate = useTenantNavigate();

  const getFloorRoomsDisplaysPaginationQuery = useCallback(
    (paginationQuery: PaginationQuery, filter: Filter) =>
    getRoomsForFloor(
        paginationQuery,
        floorId,
        filter.searchTerm
      ),
    [floorId]
  );

  const navigateToFloorRoom = (room: BaseModel) => {
    navigate(`rooms/${room.id}`);
  };
  return (
    <ItemDisplay itemName="rooms" getItems={getFloorRoomsDisplaysPaginationQuery} labelProperty={"name"} handleItemChosen={navigateToFloorRoom} />
  );
};

export default FloorRoomsDisplay;

